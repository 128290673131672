<template>
  <f7-page class="register-page">


    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/signup.svg" :alt="$t.getTranslation('LBL_SIGN_UP')" loading="Lazy" />

        <div class="info">
          <h1>{{ $t.getTranslation("LBL_SIGN_UP") }}</h1>

          <p>{{ $t.getTranslation("LBL_SIGN_UP_HEADER") }}</p>

          <f7-list class="checklist" media-list no-hairlines>


            <f7-list-item checkbox :checked="registerInfo.IsLegalAge === 1" :value="1" :title="$t.getTranslation('LBL_LEGAL_AGE')" :subtitle="$t.getTranslation('LBL_LEGAL_AGE_SUB')" @change="onLegalAgeChange"> </f7-list-item>
   

            <f7-list-item checkbox :checked="registerInfo.IsTermsAgreed === 1" :value="1" title="" :subtitle="$t.getTranslation('LBL_TERMS_AND_CONDITIONS_SUB')" @change="onTermsChange">
              <template #inner>
                <f7-link :href="$configs.externalPageLinks.termsAndConditions" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_TERMS_AND_CONDITIONS") }}</f7-link>, <f7-link :href="$configs.externalPageLinks.privacyPolicy" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_PRIVACY_POLICY") }}</f7-link>, <f7-link :href="$configs.externalPageLinks.orderAndShipping" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_ORDER_AND_SHIPPING") }}</f7-link>, <f7-link :href="$configs.externalPageLinks.returnPolicy" target="_blank" external class="no-ripple">{{ $t.getTranslation("LBL_RETURN_POLICY") }}</f7-link>
              </template>
            </f7-list-item>



            <f7-list-item
              checkbox
              :checked="registerInfo.IsNewsletterSubscribed === 1"
              :value="1"
              :title="$t.getTranslation('LBL_NEWSLETTER_PROMOTIONS')"
              :subtitle="$t.getTranslation('LBL_NEWSLETTER_PROMOTIONS_SUB')"
              @change="onNewsletterChange"
            >
            </f7-list-item>



          </f7-list>

          <f7-button fill large @click="next">{{ $t.getTranslation("LBL_CONTINUE") }}</f7-button>
        </div>
      </div>
    </section>

   
  </f7-page>
</template>

<script>

import { defineComponent, ref, computed, inject } from "vue";
import { useStore } from "@/store";
import { helpers } from "@/utils/helpers.js";
import { post, get } from "@/utils/axios";
export default defineComponent({
  name: "WechatRegisterPage",
  components: {},
  props: { f7router: Object, f7route: Object },
  setup(props) {
    const $t = inject("$translation");

    const store = useStore();
    
    const registerInfo = ref(store.getters["register/getData"]);

    const onLegalAgeChange = (event) => {
      let value = event && event.target && event.target.checked ? 1 : 0;
      store.dispatch("register/setData", {
        IsLegalAge: value,
      });
    };

    const onNewsletterChange = (event) => {
      let value = event && event.target && event.target.checked ? 1 : 0;
      store.dispatch("register/setData", {
        IsNewsletterSubscribed: value,
      });
    };

    const onTermsChange = (event) => {
      let value = event && event.target && event.target.checked ? 1 : 0;
      store.dispatch("register/setData", {
        IsTermsAgreed: value,
      });
    };

    const onPrivacyChange = (event) => {
      let value = event && event.target && event.target.checked ? 1 : 0;
      store.dispatch("register/setData", {
        IsPrivacyAgreed: value,
      });
    };

    const isDisableNextButton = computed(() => {
      let registerInfo = store.getters["register/getData"];
      return !registerInfo.IsTermsAgreed || !registerInfo.IsPrivacyAgreed;
    });

    const next = async() => {
      let registerInfo = store.getters["register/getData"];
      let referral = store.getters["referral/getData"];
      registerInfo.ReferralCode = referral?.ReferralCode;

      if (!registerInfo.IsTermsAgreed) {
        helpers.createNotification({
          type: "error",
          title: $t.getTranslation("LBL_ERROR"),
          message: $t.getTranslation("ERR_AGREE_TERMS_AND_CONDITIONS"),
        });

        return false;
      }


      if (!registerInfo.IsLegalAge) {
        helpers.createNotification({
          type: "error",
          title: $t.getTranslation("LBL_ERROR"),
          message: $t.getTranslation("ERR_AGREE_LEGAL_AGE"),
        });

        return false;
      }

      //pass if have referral code and check if able to get new user coupon
      let ret = await post("/user/wechat", registerInfo);
      if (!ret) return;

      if(ret.newUserVoucher){
         store.dispatch("referral/setData", { NewUserCoupon : true });
      };
      
      if(ret.referralUser){
         store.dispatch("referral/setData", { ReferralUser : ret.referralUser });
      };

      store.dispatch("referral/setData", { ReferralCode : null }); //set to null after usage
      
      store.dispatch("register/resetData"); //reset
      store.dispatch("user/setData", { Wechat : { IsCompleted : 1 }, IsWechatSteps: true });
      //props.f7router.navigate("/profile/settings/wine/");
      props.f7router.navigate("/register/complete/", { reloadCurrent  : true });

    };

    return { registerInfo, onLegalAgeChange, onNewsletterChange, onTermsChange, onPrivacyChange, isDisableNextButton, next }
  },
});
</script>